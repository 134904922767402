import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const BlogIndex = function ({ location, data }) {
  const [postList, setPostList] = useState(null)
  const [postObject, setPostObject] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isLoadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges
  const numberOfPosts = 4

  // Get initial posts list and display the 5 latest posts
  useEffect(() => {
    async function loadViews() {
      new Promise((resolve) => {
        setPostList(posts)
        let object = [...posts.slice(0, numberOfPosts)]
        resolve(object)
      }).then(function (object) {
        setPostObject(object)
      })
    }
    loadViews()
  }, [])

  // Lookup if there is more posts to load
  useEffect(() => {
    if (postObject !== null) {
      const isMore = postObject.length < postList.length
      setHasMore(isMore)
    }

    // Date Formating	
    let elementList = document.getElementsByClassName('postImgWrapper');
    for (var i = 0; i < elementList.length; i++) {
      var newstring = elementList[i].innerHTML.replace(".", "<span class='date1'>");
      elementList[i].innerHTML = newstring;
      var newstring2 = elementList[i].innerHTML.replace(",", "</span><span class='date2'>");
      elementList[i].innerHTML = newstring2;
      var newstring3 = elementList[i].innerHTML.replace(":", "</span><span class='date3'>");
      elementList[i].innerHTML = newstring3;
      var newstring4 = elementList[i].innerHTML.replace(";", "</span>");
      elementList[i].innerHTML = newstring4;
    }
  }, [postObject])

  // Handle loading more posts
  useEffect(() => {
    if (isLoadMore && hasMore) {
      const currentLength = postObject.length
      const isMore = currentLength < postList.length
      const nextResults = isMore ? postList.slice(currentLength, currentLength + numberOfPosts) : []
      setPostObject([...postObject, ...nextResults])
      setLoadMore(false)
    }
  }, [isLoadMore, hasMore])

  function loadMore() {
    setLoadMore(true)
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={siteTitle}
      />
      <div className='trees-mask-2'>
        <div className="postList">
          <h2
            style={{
              margin: '0 0 3rem'
            }}
          >
            Latest Articles
          </h2>
          {postObject !== null &&
            <>
              {postObject.map(({ node }) => (
                <div className="postWrapper">
                  <div className="postImgWrapper">
                    {node.frontmatter.date}
                  </div>
                  <div className="postTextWrapper">
                    <h3>
                      <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                        {node.frontmatter.title}
                      </Link>
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                      Read More ⟶
                    </Link>
                  </div>
                </div>
              ))}
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '5rem'
                }}
              >
                {hasMore ? (
                  <button
                    type="button"
                    onClick={loadMore}
                    className="button"
                  >
                    Load More +
                  </button>
                ) : (
                  <p style={{
                    fontWeight: 'bold'
                  }}>
                    No more posts... 😢
                  </p>
                )}
              </div>
            </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex


export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: ".MMMM,DD:YYYY;")
            title
          }
        }
      }
    }
  }
`